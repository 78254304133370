<template>
    <div>
        <s-crud
            title="Guía de Material"
            :filter="filter"
            height="150"
            :config="configDriverVehicle"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            
            :remove="this.$fun.getSecurity().IsLevelAdmin"
            @rowSelected="rowSelected($event)"
            @save="save($event)"
        >
        <!-- :edit="this.$fun.getSecurity().IsLevelAdmin" -->

            <template slot-scope="props">
                <v-col>
                    <v-row>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-text label="Código de compromiso" v-model="props.item.CcmCode" />
                        </v-col>
                         <v-col lg="6" class="s-col-form" cols="12">
                            <s-toolbar-carrier
                                v-model="props.item.DocumentNumberDrv"
                                @returnPerson="hadReturnPerson($event, props.item)"
                                addBirthDate
                                addTypeSex
                                label= "Documento Transportista"
                            >
                            </s-toolbar-carrier>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-text disabled label="Transportista" :placeholder="props.item.DriverName" v-model="props.item.NtpFullName" />
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-select-transport label="Vehículo" :text="plate" return-object v-model="ObjTransport"></s-select-transport>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="6" class="s-col-form" cols="12">
                            <s-toolbar-person
                                v-model="props.item.DocumentNumberPrs"
                                @returnPerson="hadReturnPersonResponsable($event, props.item)"
                                addBirthDate
                                addTypeSex
                                label= "Documento Responsable"
                            >
                            </s-toolbar-person>
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-text disabled label="Responsable" :placeholder="props.item.ResponsableName"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="s-col-form" md="12" lg="12">
                            <s-text  label="Observacion" v-model="props.item.IomObservation"></s-text>
                        </v-col>
                    </v-row>
                </v-col>
            </template>

        </s-crud>
    </div>
</template>

<script>
import _InputOutputMaterialService from "@/services/Technicalassistance/InputOutputMaterialService";
import _AsistementDriverVehicle from "@/services/Technicalassistance/AssistementDriverVehicle";
import SToolbarCarrier from "@/components/Utils/Carrier/sToolbarCarrier";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import SSelectArticle from '@/components/TecnicalAssistence/CommitmentCarrierMaterial/sSelectArticle.vue';
import SSelectCampaign from '@/components/Utils/SSelectCampaign.vue';
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
import SSelectVehicle from '@/components/Utils/Vehicle/SSelectVehicle.vue';
import SSelectTransport from '@/components/HarvestSchedule/SSelectTransport.vue';

export default {
  components: {SToolbarCarrier, SToolbarPerson, SSelectArticle, SSelectCampaign, sArticleSearch, SSelectVehicle, SSelectTransport },
  props: {

  },
  data: () => ({
    ReturPerson: null,
    dni: "",
    plate: "",
    itemSelected: {},
    objArticle:{
        
    },
    ObjTransport:{
            Transport:null,
        },
    configDriverVehicle: {
        model: {
            IomID: "ID",
            DriverName: "string",
            Plate: "string",
            ResponsableName: "string",
            IomObservation: "string"
        },
        service: _InputOutputMaterialService,
        headers: [
            {text: "ID", value: "IomID"},
            {text: "Código de compromiso", value: "CcmCode"},
            {text: "Transportista", value: "DriverName"},
            {text: "Vehiculo", value: "Plate"},
            {text: "Responsable", value: "ResponsableName"},
            {text: "Observación", value: "IomObservation"},
            
        ],
    },
  }),
  methods: {
    returnObject(){
        alert();
    },
    hadReturnPerson(value, item){
        item.NtpFullName = value.NtpFullName;
        item.DrvID = value.PrsID;
        this.dni = value.PrsDocumentNumber;
    
    },
    hadReturnPersonResponsable(value, item){
        item.ResponsableName = value.NtpFullName;
        item.PrsID = value.PrsID;
    },
    Initialize() {
      this.filter = {  };
    },
    rowSelected(rows)
    {
        if (rows.length > 0) {
            this.itemSelected = rows;
            this.$emit('rowSelected', rows);
        }
    },

    save(item){
        if(isNaN(item.DrvID)){
            this.$fun.alert("Ingrese Transportista", "error");
            return;
        }
        if(this.plate == ""){
            this.$fun.alert("Transportista no tiene vehículo asignado", "error");
            return;
        }
        if(this.ResponsableName == ""){
            this.$fun.alert("Ingrese responsable", "error");
            return;
        }
        item.VehID = this.ObjTransport.VehID;
        item.SecStatus = 1;
        item.save();
    },
    
  },

  watch:{
      dni(){
        _AsistementDriverVehicle.getvehiclebycarrier(this.dni)
            .then((r) => {
                /* this.ObjTransport.VehID = r.data.VehID;
                this.ObjTransport.VehPlate = r.data.AdvPlaqueVehicle; */
                this.plate = r.data.AdvPlaqueVehicle;             
            });
      }
  },
  created() {
    this.filter = {};
  },
};
</script>
