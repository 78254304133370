<template>
    <div>
        <s-crud
            title="Detalle de Guía de Material"
            :filter="filter"
            height="210"
            :config="configDriverVehicle"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            :remove="this.$fun.getSecurity().IsLevelAdmin"
            @rowSelected="rowSelected($event)"
            @save="saveAll($event)"
            deleteNoFunction
            @delete="deleteDetailGuia"
            ref="crudMaterialGuiaDetail"
        >

            <template slot-scope="props">
                <v-col>
                    <v-row>
                        <s-toolbar 
                            remove 
                            add  
                            @add="addGuiaDetail(props.item)"
                            @removed="removeGuiaDetail()"
                            label="Detalle Material"
                        >
                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-article-search v-model="objArticle"></s-article-search>
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="2">
                            <s-text label="Cantidad" number v-model="props.item.IodCount"/>
                        </v-col>
                        <!-- <v-col cols="12" class="s-col-form" lg="2">
                            <s-text label="Color" v-model="props.item.IodColor"/>
                        </v-col> -->
                        <v-col cols="12" class="s-col-form" lg="2">
                            <s-text label="Vacias" placeholder="0" number v-model="props.item.IodEmpty"/>
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="2">
                            <s-text label="Llenas" placeholder="0" number v-model="props.item.IodFull"/>
                        </v-col>

                        <v-col>
                            <v-data-table
                            v-model="arrayGuiaDetail"
                            :headers="headersDataTable"
                            :items="guiaDetail"
                            item-key="ArtID"
                            show-select 
                            class="elevation-1">
                            </v-data-table>
                        </v-col>
                        <v-container>
                            <v-row justify="center">
                                <v-col lg="6">
                                    <v-btn @click="clearOption()" small block><v-icon x-small left>fas fa-undo-alt</v-icon>Limpiar</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </template>

        </s-crud>
    </div>
</template>

<script>
import _InputOutputMaterialDetailService from "@/services/Technicalassistance/InputOutputMaterialDetailService";
import _AsistementDriverVehicle from "@/services/Technicalassistance/AssistementDriverVehicle";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";

export default {
  components: {SToolbarPerson, sArticleSearch, },
  props: {
    IomID: { type: Number, default: 0 },
    
  },
  data: () => ({
      IodCount: "",
      IodColor: "",
      IodEmpty: "",
      IodFull: "",
      arrayGuiaDetail: [],
    ReturPerson: null,
    dni: "",
    plate: "",
    filter: {},
    itemSelected: {},
    guiaDetail: [],
    objArticle:{
        
    },
    ObjTransport:{
            Transport:null,
    },
    configDriverVehicle: {
        model: {
            IodID: "ID",
            ArtID: "int",
            IodCount: "int",
            /* IodColor: "int", */
            IodEmpty: "int",
            IodFull: "int",
            ItemName: "string"
        },
        service: _InputOutputMaterialDetailService,
        headers: [
            {text: "ID", value: "IodID"},
            {text: "Articulo", value: "ItemName"},
            {text: "Cantidad", value: "IodCount"},
            /* {text: "Color", value: "IodColor"}, */
            {text: "Vacias", value: "IodEmpty"},
            {text: "Llenas", value: "IodFull"},
        ],
    },
    headersDataTable:[
        {text:"ID",value:"ArtID"},
        {text: "Articulo", value: "ArtDescription"},
        {text: "Cantidad", value: "IodCount"},
        {text: "Color", value: "IodColor"},
        {text: "Vacias", value: "IodEmpty"},
        {text: "Llenas", value: "IodFull"},
    ],
  }),
  methods: {
    saveAll()
    {
        if(this.guiaDetail.length == 0)
        {
            this.$fun.alert("Grilla vacía", "error");
            return;
        }
        if(this.IomID == 0){
            this.$fun.alert("Ingrese guía", "error");
            return;
        }
        this.$fun.alert("Seguro de guardar", "question")
            .then((r) => {
                if(r.value){
                    _InputOutputMaterialDetailService
                    .save(this.guiaDetail, this.$fun.getUserID())
                    .then((resp) => {
                        this.$fun.alert("Guardado Correctamente", "success");
                        this.clearOption();
                        this.$refs.crudMaterialGuiaDetail.refresh();
                    })
                }
            });
        //this.$refs.crudMaterialGuiaDetail.refresh();
    },
    deleteDetailGuia()
    {
        this.itemSelected[0].SecStatus = 0;
        this.guiaDetail = this.itemSelected;
        console.log('this.guiaDetail', this.guiaDetail)
        this.$fun.alert("Seguro de eliminar", "question").then((r) => {
            if(r.value){
                _InputOutputMaterialDetailService
                .save(this.guiaDetail, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.clearOption();
                    this.$refs.crudMaterialGuiaDetail.refresh();
                })
            }
        });
    },
    addGuiaDetail(item){
        if(item.IodEmpty == ""){
            item.IodEmpty = "0"
        }
        if(item.IodFull == ""){
            item.IodFull = "0"
        }
        let obj = {
            ArtDescription: this.objArticle.ArtName,
            IodCount: item.IodCount,
            IodColor: item.IodColor,
            IodEmpty: item.IodEmpty,
            IodFull: item.IodFull,
            ArtID: this.objArticle.ArtCode,
            IomID: this.IomID
        };
        console.log(item);
        if(!obj.ArtDescription != ""){
            this.$fun.alert("Ingrese artículo", "error");
            return;
        }
        if(item.IodCount == ""){
            this.$fun.alert("Ingrese cantidad", "error");
            return;
        }/* 
        if(item.IodColor == ""){
            this.$fun.alert("Ingrese color", "error");
            return;
        } */
        /* console.log(item.IodEmpty == "");
        if(item.IodEmpty == ""){
            this.$fun.alert("Ingrese la cantidad de vacias", "error");
            return;
        }
        if(item.IodFull == ""){
            this.$fun.alert("Ingrese la cantidad de llenas", "error");
            return;
        } */
        let amountBrought = parseInt(obj.IodEmpty) + parseInt(obj.IodFull);
        let count = parseInt(obj.IodCount);
        if(count < amountBrought){
            this.$fun.alert("Cantida de vacias y llenas excede la cantidad", "error");
            return;
        }
        this.guiaDetail.push(obj);
        this.clearForm(item);
    },
    removeGuiaDetail(){
        this.arrayGuiaDetail.forEach((element) => {
            this.guiaDetail = this.guiaDetail.filter(
            (x) => x.ArtID != element.ArtID
            );
        });
    },
    clearForm(item)
    {
        this.objArticle = null;
        item.IodCount = "";
        item.IodColor = "";
        item.IodEmpty = "";
        item.IodFull = "";
    },

    clearOption()
    {
        this.guiaDetail = []
    },
    hadReturnPerson(value, item){
        item.NtpFullName = value.NtpFullName;
        item.DrvID = value.PrsID;
        this.dni = value.PrsDocumentNumber;
    },
    Initialize() {
        this.filter = { IomID: this.IomID };
    },
    rowSelected(rows)
    {
        if (rows.length > 0) {
            this.itemSelected = rows;
        }
    },

    /* save(item){
        item.VehID = this.ObjTransport.VehID;
        item.SecStatus = 1;
        item.save();
        this.Objvehiculo = {}
    }, */
    
  },

  watch:{
    IomID(){
        this.Initialize();
    }
  },
  created() {
    this.filter = {IomID: this.IomID};
  },
};
</script>
