<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <material-guide @rowSelected="rowSelected($event)"></material-guide>
      </v-col>
      <v-col cols="12" md="12" lg="12">
        <material-guide-detail :IomID="IomID"></material-guide-detail>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import FarmLot from "@/views/TechnicalAssistance/Certification/FarmLot";
import Certification from "@/views/TechnicalAssistance/Certification/Certification";
import MaterialGuide from './MaterialGuide.vue';
import MaterialGuideDetail from './MaterialGuideDetail.vue';
/* import Producer from "@/views/TechnicalAssistance/Certification/Producer"; */

export default {
  components: { FarmLot, Certification, MaterialGuide, MaterialGuideDetail},
  data(){
    return {
      IomID: 0,
    };
  },
  methods: {
    rowSelected(rows) {
      this.IomID = rows[0].IomID;
    },
  },
};
</script>
