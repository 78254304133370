import Service from "../Service";

const resource = "inputoutputmaterialdetail/";

export default {
    save(tiomd, requestID) {
        console.log('desde el servicio', tiomd)
        return Service.post(resource + "save", tiomd, {
            params: {requestID}
        });
    },

    pagination(parameters, requestID){
        return Service.post(resource + "pagination", parameters, {
            params: {requestID}
        });
    }
}